import {
  READ_REDDIT_PENDING,
  READ_REDDIT_SUCCESS,
  READ_REDDIT_FAIL,
} from '../actions/actionTypes'

const initialState = {
  list: [],
  error: false,
  isFetching: true,
  category: ''
};

export default (state = initialState, action) => {
  switch (action.type) {

    case READ_REDDIT_PENDING:
    {
      return {
        ...state,
        isFetching: true,
        message: "",
        error: false
      }
    }
    case READ_REDDIT_FAIL:
    {
      return {
        ...state,
        isFetching: false,
        message: action.message,
        error: true
      }
    }
    case READ_REDDIT_SUCCESS:
    {
      return {
        ...state,
        isFetching: false,
        list: action.items,
        error: false,
        category: action.link
      }
    }
      
    default:
      return state;
  }
};
