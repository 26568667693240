import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    categories: [],
    pathCategories: [],
    loaded: false
}

const setCategories = (state, action) => {
    const categories = action.categories;
    const pathCategories = categories.map(cat => (cat.split(" ").join("-").toLowerCase()));
    const newState = updateObject(state, {
        categories: categories,
        pathCategories: pathCategories,
        loaded: true
    })
    return newState
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CATEGORIES: return setCategories(state, action)
        default: return state
    }
};

export default reducer