import {
  READ_MEETUP_PENDING,
  READ_MEETUP_SUCCESS,
  READ_MEETUP_FAIL,
} from '../actions/actionTypes'

const initialState = {
  list: [],
  error: false,
  isFetching: true,
  category: ''
};

export default (state = initialState, action) => {
  switch (action.type) {

    case READ_MEETUP_PENDING:
      {
        return {
          ...state,
          isFetching: true,
          message: "",
          error: false
        }
      }
    case READ_MEETUP_FAIL:
      {
        return {
          ...state,
          isFetching: false,
          message: action.message,
          error: true
        }
      }
    case READ_MEETUP_SUCCESS:
      {
        return {
          ...state,
          isFetching: false,
          list: action.items,
          error: false,
          category: action.link
        }
      }

    default:
      return state;
  }
};
