export const SET_CATEGORIES = 'SET_CATEGORIES';
export const CHANGE_CATEGORIES = 'CHANGE_CATEGORIES';

export const SET_TWEETS = 'SET_TWEETS';
export const SET_TWEET_CATEGORIES = 'SET_TWEET_CATEGORIES';

export const SET_SOCIAL_MEDIA_CATEGORIES = 'SET_SOCIAL_MEDIA_CATEGORIES';
export const SET_SOCIAL_MEDIA_ITEMS = 'SET_SOCIAL_MEDIA_ITEMS';

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";

export const READ_YOUTUBE_PENDING = 'READ_YOUTUBE_PENDING';
export const READ_YOUTUBE_SUCCESS = 'READ_YOUTUBE_SUCCESS';
export const READ_YOUTUBE_FAIL = 'READ_YOUTUBE_FAIL';

export const READ_MEETUP_PENDING = 'READ_MEETUP_PENDING';
export const READ_MEETUP_SUCCESS = 'READ_MEETUP_SUCCESS';
export const READ_MEETUP_FAIL = 'READ_MEETUP_FAIL';

export const READ_TWITTER_HOME_PENDING = 'READ_TWITTER_HOME_PENDING';
export const READ_TWITTER_HOME_SUCCESS = 'READ_TWITTER_HOME_SUCCESS';
export const READ_TWITTER_HOME_FAIL = 'READ_TWITTER_HOME_FAIL';
export const TWITTER_HOME_EXTRA = 'TWITTER_HOME_EXTRA'

export const READ_TWITTER_SOCIAL_PENDING = 'READ_TWITTER_SOCIAL_PENDING';
export const READ_TWITTER_SOCIAL_SUCCESS = 'READ_TWITTER_SOCIAL_SUCCESS';
export const READ_TWITTER_SOCIAL_FAIL = 'READ_TWITTER_SOCIAL_FAIL';

export const READ_REDDIT_PENDING = 'READ_REDDIT_PENDING';
export const READ_REDDIT_SUCCESS = 'READ_REDDIT_SUCCESS';
export const READ_REDDIT_FAIL = 'READ_REDDIT_FAIL';

export const SET_DEVICE_ID = 'SET_DEVICE_ID';
export const SET_CHAT_CLIENT = 'SET_CHAT_CLIENT'
export const AUTH_LOGIN_PENDING = 'AUTH_LOGIN_PENDING';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_ANONYMOUS_LOGIN_SUCCESS = 'AUTH_ANONYMOUS_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
export const AUTH_SIGNUP_PENDING = 'AUTH_SIGNUP_PENDING';
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';
export const AUTH_SIGNUP_FAIL = 'AUTH_SIGNUP_FAIL';
export const SET_AUTH = 'SET_AUTH';