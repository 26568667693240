import {
    SET_DEVICE_ID,
    AUTH_LOGIN_PENDING,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_FAIL,
    AUTH_ANONYMOUS_LOGIN_SUCCESS,
    AUTH_SIGNUP_PENDING,
    AUTH_SIGNUP_SUCCESS,
    AUTH_SIGNUP_FAIL,
    AUTH_LOGOUT,
    SET_AUTH
} from '../actions/actionTypes'

const initialState = {
    loading: false,
    error: null,
    anonymous: true,
    isLoggedIn: false,
    deviceId: null,
    accessToken: null,
    expiresAt: null,
    email: null,
    nickname: null,
    picture: null,
    authId: null,
    signUpError: null,
    hasSignedUp: false,
    auth: null
};

export default (state = initialState, action) => {
    switch (action.type) {

        case SET_DEVICE_ID:
            {
                return {
                    ...state,
                    deviceId: action.deviceId
                }
            }
        case AUTH_LOGIN_PENDING:
            {
                return {
                    ...state,
                    loading: true
                }
            }
        case AUTH_LOGIN_SUCCESS:
            {
                return {
                    ...state,
                    loading: false,
                    anonymous: false,
                    isLoggedIn: true,
                    error: null,
                    accessToken: action.accessToken,
                    expiresAt: action.expiresAt,
                    email: action.email,
                    nickname: action.nickname,
                    picture: action.picture,
                    authId: action.authId
                }
            }
        case AUTH_LOGIN_FAIL:
            {
                return {
                    ...state,
                    loading: false,
                    isLoggedIn: false,
                    error: action.error
                }
            }
        case AUTH_ANONYMOUS_LOGIN_SUCCESS:
            {
                if (state.isLoggedIn && !state.anonymous) {
                    return {
                        ...state
                    }
                }
                return {
                    ...state,
                    loading: false,
                    anonymous: true,
                    isLoggedIn: true,
                    error: null,
                    accessToken: action.accessToken,
                    expiresAt: action.expiresAt,
                    email: action.email,
                    nickname: action.nickname,
                    picture: action.picture,
                    authId: action.authId
                }
            }
        case AUTH_SIGNUP_PENDING:
            {
                return {
                    ...state,
                    hasSignedUp: false,
                    signUpError: null
                }
            }
        case AUTH_SIGNUP_SUCCESS:
            {
                return {
                    ...state,
                    hasSignedUp: true
                }
            }
        case AUTH_SIGNUP_FAIL:
            {
                return {
                    ...state,
                    signUpError: action.signUpError
                }
            }
        case AUTH_LOGOUT:
            {
                return {
                    ...state,
                    accessToken: null,
                    expiresAt: null,
                    email: null,
                    nickname: null,
                    picture: null,
                    authId: null,
                    isLoggedIn: false,
                    anonymous: true
                }
            }
        case SET_AUTH:
            {
                return {
                    ...state,
                    auth: action.auth
                }
            }
        default:
            return state;
    }
};
