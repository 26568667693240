import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    categories: null,
    loadedCategories: false,
    socialMedias: ['Facebook', 'Twitter', 'YouTube', 'Instagram', 'Reddit'],
    lowerCaseMedia: []
}

const setSocialMediaCategories = (state, action) => {
    const categories = action.categories.map(cat => (cat.split(" ").join("-").toLowerCase()));
    if (categories.length === 0) {
        return state
    }
    let newParams = {};
    for (var i = 0; i < categories.length; i++) {
        newParams[categories[i]] = { items: null, loadedItems: false }
    }
    let newSocialMedias = {}
    const lowerCaseMedia = state.socialMedias.map(media => media.toLowerCase())
    for (i = 0; i < lowerCaseMedia.length; i++) {
        newSocialMedias[lowerCaseMedia[i]] = newParams;
    }
    return updateObject(state, {
        categories: categories,
        loadedCategories: true,
        lowerCaseMedia: lowerCaseMedia,
        ...newSocialMedias
    })
}

const setSocialMediaItems = (state, action) => {
    if (!state.loadedCategories) {
        return state;
    }
    let updatedCategory = {};
    updatedCategory[action.socialMedia] = {
        ...state[action.socialMedia],
        [action.category]: {
            items: action.items,
            loadedItems: true
        }
    }
    return updateObject(state, { ...updatedCategory })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SOCIAL_MEDIA_CATEGORIES: return setSocialMediaCategories(state, action);
        case actionTypes.SET_SOCIAL_MEDIA_ITEMS: return setSocialMediaItems(state, action);
        default: return state;
    }
}

export default reducer;