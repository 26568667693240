import React from "react";
import ReactDOM from "react-dom";
// import App from './App'
import App from './UnderConstruction'
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
// import { composeWithDevTools } from "redux-devtools-extension";
import categoriesReducer from './store/reducers/category';
import tweetsReducer from './store/reducers/tweets';
import socialMediaReducer from './store/reducers/socialMedia';
// import authReducer from './store/reducers/auth';
import twitterHome from './store/reducers/twitterHome'
import twitterSocial from './store/reducers/twitterSocial'
import reddit from './store/reducers/reddit'
import youtube from './store/reducers/youtube'
import meetup from './store/reducers/meetup'
import auth2 from './store/reducers/auth2'
import thunk from 'redux-thunk';
import "babel-polyfill"

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ 
//   trace: true, 
//   traceLimit: 25 
// }) || compose;

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = compose;

const rootReducer = combineReducers({
  categories: categoriesReducer,
  tweets: tweetsReducer,
  socialMedia: socialMediaReducer,
  // auth: authReducer,
  auth2: auth2,
  twitterHome: twitterHome,
  twitterSocial: twitterSocial,
  reddit: reddit,
  youtube: youtube,
  meetup: meetup,
});

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));
// store.dispatch(verifyAuth())

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById("root")
);