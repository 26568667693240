import React, { Component } from "react";

class App extends Component {

  render() {
      return (
        <div style={{textAlign: 'center', marginTop: '350px'}}>
          <h1 style={{color: 'black', fontWeight: '500', fontSize: '2.5rem', margin: '0 10px'}}>
            Sorry for the inconvenience, The website is under construction.
          </h1>
        </div>
      )
  }
}

export default App