import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    categories: [],
    categoriesNames: [],
    loadedCategories: false,
}

const setTweetCategories = (state, action) => {
    const categories = action.categories.map(cat => (cat.split(" ").join("-").toLowerCase()));
    if (categories.length === 0) {
        return state
    }
    let newParams = {};
    for (var i = 0; i < categories.length; i++) {
        newParams[categories[i]] = { tweets: null, loadedTweets: false }
    }
    return updateObject(state, {
        categoriesNames: action.categories,
        categories: categories,
        loadedCategories: true,
        ...newParams
    })
}

const setTweets = (state, action) => {
    if (!state.loadedCategories) {
        return state;
    }
    let updatedCategory = {};
    updatedCategory[action.category] = {
        tweets: action.tweets,
        loadedTweets: true
    }
    return updateObject(state, {...updatedCategory})
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TWEET_CATEGORIES: return setTweetCategories(state, action);
        case actionTypes.SET_TWEETS: return setTweets(state, action);
        default: return state;
    }
}

export default reducer;