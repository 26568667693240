import {
  READ_TWITTER_HOME_PENDING,
  READ_TWITTER_HOME_SUCCESS,
  READ_TWITTER_HOME_FAIL,
  TWITTER_HOME_EXTRA
} from '../actions/actionTypes'

const initialState = {
  list: [],
  error: false,
  isFetching: true,
  category: ''
};

export default (state = initialState, action) => {
  switch (action.type) {

    case READ_TWITTER_HOME_PENDING:
    {
      return {
        ...state,
        isFetching: true,
        message: "",
        error: false
      }
    }
    case READ_TWITTER_HOME_FAIL:
    {
      return {
        ...state,
        isFetching: false,
        message: action.message,
        error: true
      }
    }
    case READ_TWITTER_HOME_SUCCESS:
    {
      return {
        ...state,
        isFetching: false,
        list: action.items,
        error: false,
        category: action.link
      }
    }

    case TWITTER_HOME_EXTRA:
      {
        return {
          ...state,
          list: state.list.concat(action.items)
        }
      }
      
    default:
      return state;
  }
};
